<template>
  <div class="row">
    <div class="col-lg-8 m-auto">

        <template v-if="success">

            <v-alert outlined prominent border="left"  type="success">
              {{ success }}
             
             <span v-if="!activated">
                <hr>
               <p>We are so excited that you heard about Mango & Lola and decided to visit our site!!!</p>
               <p>We will be launching soon, and you will be the first one to know when we go LIVE.</p>               
             </span>
            </v-alert>
            <button v-if="activated" @click="$router.push({name: 'login'})" class="tw-mt-6 tw-flex tw-justify-center tw-px-6 tw-py-2 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
              {{ $t('auth.login') }}
            </button>


        </template>
        <template v-else>

          <v-alert outlined prominent border="left"  type="error">
              <v-row align="center">
                <v-col class="grow">
                  {{ error || $t('auth.failed_to_verify_email') }}
                </v-col>
                <v-col class="shrink">
                  <button @click="$router.push({name: 'verification.resend'})" class="tw-mt-6 tw-flex tw-justify-center tw-px-6 tw-py-2 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
                    {{ $t('auth.resend_verification_link') }}
                  </button>

                </v-col>
              </v-row>
          </v-alert>

        </template>
    
    </div>
  </div>
</template>

<script>

import i18n from '@/i18n'

const qs = (params) => Object.keys(params).map(key => `${key}=${params[key]}`).join('&')

export default {
  //middleware: 'guest',
  metaInfo () {
    return { title: i18n.t('auth.verify_email') }
  },


  async beforeRouteEnter (to, from, next) {
    try {
      const { data } = await this.$http.post(`/api/email/verify/${to.params.id}?${qs(to.query)}`)
      next(vm => { 
        vm.success = data.status;
        vm.type = data.type;
        vm.activated = data.activated;
        })
    } catch (e) {
      next(vm => { vm.error = e.data.status })
    }
  },

  data: () => ({
    error: '',
    success: '',
    type: null,
    activated: null
  })
}
</script>
